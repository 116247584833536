export default function getEpiWeek(date) {
  const thisDate = new Date(date)
  const target = new Date(thisDate.valueOf())
  const dayPs = (thisDate.getDay() + 7) % 7
  target.setDate(target.getDate() - dayPs + 3)
  const jan4 = new Date(target.getFullYear(), 0, 4)
  const dayDifference = (target - jan4) / 86400000
  if (target.getFullYear()%4===0) {
    return 1 + Math.ceil(dayDifference / 7)
  } else {
    return Math.ceil(dayDifference / 7)
  }
}
